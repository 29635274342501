/**This file contains a constant object that has properties that are necessary
 * for the build found in the `dev` configuration in `angular.json`.
 */

export const environment = {
	production: true,
	baseUrl: 'https://slpsp-service-reparenting-or6b4z5fya-uk.a.run.app/slpsp-api/',
	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2',
	openidClientId: 'urn:slpsp:clientid:web_slpsp:dev',
	openidResource: 'urn:slpsp:resource:web_slpsp:dev',
	openidRedirectUrl: 'https://dev.slpsp.ford.com',
	currentPage: '',
	accessmanagement:
		'https://slpsp-service-accessmgmt-or6b4z5fya-uk.a.run.app',
};
